.shadow {
  -webkit-box-shadow: -4px 2px 8px -1px rgba(156, 156, 156, 0.68);
  -moz-box-shadow: -4px 2px 8px -1px rgba(156, 156, 156, 0.68);
  box-shadow: -4px 2px 8px -1px rgba(156, 156, 156, 0.68);
}

.calendar-view {
  padding: 21px 5px !important;
  width: 82vw !important;
  display: flex;
}

.recurrence-label-content {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  width: 200px;
}

.recurrence-input-label {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.event-details {
  border: 1px solid #ddd;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.event-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.textFieldWithArrows .MuiInputBase-input[type="number"]::-webkit-inner-spin-button,
.textFieldWithArrows .MuiInputBase-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: textfield;
  margin: 0.1;
}

.textFieldWithArrows .MuiInputBase-input[type="number"] {
  -moz-appearance: textfield;
}

.selected-item {
  background-color: #03b3ff1e !important;
}

.event-type-select-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.event-type-select-icon {
  height: 19px !important;
  margin-right: 12px;
}

.my-calendar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow-y: auto;
}

.calendar-view-list {
  max-height: 145px !important;
  min-height: 10px !important;
  overflow-y: auto !important;
}

.button-week-view {
  background-color: transparent !important;
  color: black !important;
}

.week-view-container {
  border: 1px solid rgba(0, 0, 0, 0.452);
  background-color: rgb(225 246 255 / 75%);
}

.week-view:hover {
  background-color: rgb(240 240 240);
}

.language-list {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.list-item-text {
  font-size: 14px !important;
}

.menu-item-option {
  background-color: transparent !important;
  margin-bottom: 0 !important;
  padding-right: 3px !important;
  color: #616161 !important;
}

.event-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-item-option:hover {
  background-color: #94a3b81f !important;
}

.menu-selected-option {
  background-color: #94a3b81f !important;
}

.dashboard-container-menu {
  display: none !important;
}

.selected-filter-option {
  background-color: #eaf6ff !important;
}

.event-popper {
  z-index: 6;
}

.hidden {
  display: none !important;
}

.people-calendar-search {
  display: block !important;
  animation: fadeIn 0.6s;
  align-self: center;
}

.calendar-lists-text {
  overflow: hidden;
  padding: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

@media (max-width: 1000px) {
  .dashboard-container {
    display: none !important;
  }

  .dashboard-container-menu {
    display: block !important;
  }

  .calendar-lists {
    max-height: none !important;
    min-height: 10px !important;
    overflow-y: hidden !important;
  }

  .calendar-view-list {
    max-height: none !important;
    min-height: 10px !important;
    overflow-y: hidden !important;
  }

  .calendar-view {
    width: 100vw !important;
  }

  .my-calendar {
    min-width: 700px !important;
    min-height: 700px !important;
  }

  .event-details {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 5;
    width: 40% !important;
    height: 100% !important;
  }
}

@media (max-width: 900px) {
  .event-form-icon {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .calendar-view {
    overflow-x: auto !important;
    padding-top: 0 !important;
  }

  .calendar-header-items {
    min-width: 600px !important;
  }

  .shadow {
    overflow-x: auto !important;
  }

  .event-details {
    width: 60% !important;
  }

  .event-popper {
    scale: 0.7;
  }

  .shadow {
    min-height: 75px;
  }
}

@media (max-width: 780px) {
  .recurrence-content {
    flex-direction: column !important;
  }
}

@media (min-width: 781px) {
  .recurrence-content {
    flex-direction: row !important;
  }
}

@media (max-height: 820px) {
  .my-calendar {
    height: 100% !important;
    overflow: hidden !important;
  }

  .app-container {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  .calendar-view-list {
    max-height: none !important;
  }

  .calendar-lists {
    max-height: 310px;
    min-height: 100px;
    overflow-y: auto;
    margin: 0 21px;
    margin-bottom: 10px;
  }

  .calendar-lists-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 190px;
  }

  .event-details {
    max-height: 82vh !important;
    height: auto;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}